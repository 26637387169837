<template>
    <div id="detail-container" class="mcontainer">
        <bread-crumb :items="breadcrumbs" />
        <div class="card p-3 mt-4">
            <h5 class="font-bold text-heading">Progress skripsi mahasiswa</h5>
            <div class="flex w-full mt-2 items-center">
                <div>
                    <span class="circle-icon mr-2">
                        <img src="/icons/file.svg" alt="" sw>
                    </span>
                </div>
                <div class="w-full">
                    <input-slider-progress :skripsi-id="data.id" :value="progress" :mentors="data.mentors" @change="(val) => progress = val" />
                </div>
                <div class="ml-4" style="width: 250px;">
                    <a href="" @click.prevent="finishSkripsi" class="btn btn-primary block" :class="{ disabled: !canFinish }" :disabled="!canFinish">
                        <b-spinner v-if="submitLoading" label="Loading..." small />
                        Skripsi Selesai
                    </a>
                </div>
            </div>
        </div>
        <span v-if="showError" class="text-danger small">Skripsi dapat diselesaikan setelah semua bimbingan selesai</span>

        <b-row class="mt-4">
            <b-col md="3">
                <list-schedule :data="data" />
            </b-col>
            <b-col md="9">
                <div class="flex justify-between">
                    <h5 class="font-bold color-dark">Mahasiswa Bimbingan Skripsi</h5>
                </div>
                <div class="mt-4">
                    <loading :loading="loading" spinner-class="justify-center">
                        <list-bimbingan :data="data" redirect-route="DetailMahasiswaJadwalBimbingan" />
                    </loading>
                    <div v-if="data.bimbingan && data.bimbingan.length < 1" class="card p-4">
                        <span class="text-center">Tidak ada pengajuan jadwal bimbingan</span>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import BreadCrumb from '@/components/BreadCrumb'
    import ListSchedule from '@/components/Bimbingan/ListSchedule'
    import ListBimbingan from '@/components/Bimbingan/ListBimbingan'
    import InputSliderProgress from '@/components/InputSliderProgress'

    import { mapActions, mapState } from 'vuex'

    export default {
        components: { BreadCrumb, ListSchedule, ListBimbingan, InputSliderProgress },
        computed: {
            ...mapState('skripsi', { data: 'data', loading: 'loading' }),
            ...mapState('auth', { user: 'user' }),
            schedule () {
                if (this.data && this.data.bimbingan) {
                    return this.data.bimbingan.filter(i => {
                        const hasMentor = i.mentors.find(mentor => mentor.id === this.user.id)
                        return (hasMentor && i.status === 2)
                    })
                }
                return []
            },
            showError () {
                if (this.progress < 100) return false

                if (this.data.bimbingan) {
                    var hasUnApproved = false

                    this.data.bimbingan.forEach(i => {
                        i.mentors.forEach(mentor => {
                            if (mentor.id === this.user.id && !mentor.finished) {
                                hasUnApproved = true
                            }
                        })
                    })

                    if (hasUnApproved) return true
                }

                return false
            },
            canFinish () {
                if (this.progress < 100) return false

                if (this.data.bimbingan) {
                    var hasUnApproved = false

                    this.data.bimbingan.forEach(i => {
                        i.mentors.forEach(mentor => {
                            if (mentor.id === this.user.id && !mentor.finished) {
                                hasUnApproved = true
                            }
                        })
                    })

                    if (hasUnApproved) return false
                }

                if (this.finished) return false

                return true
            }
        },
        data () {
            return {
                breadcrumbs: [
                    {
                        route: 'Bimbingan',
                        label: 'Skripsi',
                        active: true
                    },
                    {
                        route: 'MahasiswaBimbingan',
                        label: 'Mahasiswa Bimbingan Skripsi'
                    }
                ],
                submitLoading: false,
                progress: 0,
                finished: false
            }
        },
        methods: {
            ...mapActions('skripsi', { fetch: 'fetch', update: 'update' }),
            finishSkripsi () {
                var mentor = this.data.mentors.find(mentor => mentor.id === this.user.id)
                if (mentor) {
                    mentor.finished = true
                }

                this.submitLoading = true
                this.update({ id: this.$route.params.id, data: { mentors: [mentor] } })
                    .then(() => {
                        this.fetchSkripsi()
                    })
                    .finally(() => {
                        this.submitLoading = false
                    })
            },
            fetchSkripsi () {
                this.fetch({ id: this.$route.params.id })
                    .then(res => {
                        if (res.mentors) {
                            res.mentors.forEach(i => {
                                if (i.id === this.user.id) {
                                    this.progress = i.progress
                                    this.finished = i.finished
                                }
                            })
                        }
                    })
                    .catch(() => {
                        this.$swal({
                            type: 'error',
                            title: 'Oops...',
                            text: 'Something went wrong!'
                        })
                    })
            }
        },
        mounted () {
            this.fetchSkripsi()
        }
    }
</script>
